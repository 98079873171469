import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Menu, message } from 'antd';
import React, { useState } from 'react';
import { useAxiosPost } from '../../configs/axios';
import { localStorageName } from '../../configs/constants';
import useLocalStorage from '../../configs/localStorage';
import ProfileModal from './ProfileModal';

const HeaderContent = (props) => {
  const [auth, setAuth] = useLocalStorage(localStorageName, null);
  const { toggleSidebar } = props;
  const [profileModalVisible, setProfileModalVisible] = useState(false);

  // const { request: logoutRequest } = useAxiosPost('/auth/logout');

  return (
    <div
      style={{
        height: '64px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '50%',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <Button
          type="primary"
          shape="circle"
          icon={<MenuOutlined />}
          onClick={() => toggleSidebar()}
        />
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          height: '100%',
        }}
      >
        <ProfileModal
          status={profileModalVisible}
          closeModal={() => setProfileModalVisible(false)}
        />
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  label: (
                    <a
                      onClick={() => {
                        setProfileModalVisible(true);
                      }}
                    >
                      Profile
                    </a>
                  ),
                  key: '6',
                },
                {
                  type: 'divider',
                },
                {
                  label: (
                    <a
                      onClick={async () => {
                        try {
                          // await logoutRequest();
                          setAuth(null);
                          location.replace('/login');
                        } catch (error) {
                          console.log(
                            '🚀 ~ file: HeaderContent.jsx:395 ~ onClick={ ~ error',
                            error,
                          );
                          message.error('Error logging out');
                        }
                      }}
                    >
                      Logout
                    </a>
                  ),
                  key: '3',
                },
              ]}
            />
          }
          on
          style={{
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              cursor: 'pointer',
            }}
          >
            <Avatar
              style={{
                marginRight: 10,
                color: 'white',
                backgroundColor: 'rgb(0,100,192)',
              }}
              size={40}
              icon={<UserOutlined />}
            />
            <span>{auth?.user?.userName}</span>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default HeaderContent;
