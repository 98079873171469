import {
 Button, Form, Input, message, Modal,
} from 'antd';
import React from 'react';
import { useAxiosPut } from '../../configs/axios';

function ProfileModal(props) {
  const { status, closeModal } = props;
  const { request: passwordChangeRequest, loading: passwordChangeLoading } = useAxiosPut('/auth/resetPassword');
  const onChangePassword = async (values) => {
    try {
      await passwordChangeRequest(values);
      message.success('Password changed successfully');
    } catch (error) {
      console.log('🚀 ~ file: ProfileModal.jsx:14 ~ onChangePassword ~ error', error);
      message.error('Something went wrong');
    }
  };
  return (
    <Modal
      title="Profile"
      destroyOnClose
      open={status}
      onCancel={() => {
        closeModal();
      }}
      footer={null}
      centered
    >
      <Form layout="vertical" onFinish={onChangePassword}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please input your new password!',
            },
          ]}
          label="Old Password"
          name="oldPassword"
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please input your new password!',
            },
            {
              min: 8,
              message: 'Password must be at least 8 characters',
            },
          ]}
          label="New Password"
          name="newPassword"
        >
          <Input type="password" />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          loading={passwordChangeLoading}
        >
          Submit
        </Button>
      </Form>
    </Modal>
  );
}
export default ProfileModal;
