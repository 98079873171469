import { Layout } from 'antd';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HeaderContent from './components/layout/HeaderContent';
import SidebarContent from './components/layout/SidebarContent';
import { localStorageName } from './configs/constants';
import useLocalStorage from './configs/localStorage';
import Login from './pages/Login';
import routes from './routes';
import store from './store';

const { Header, Sider, Content } = Layout;

function App() {
  const [auth, setAuth] = useLocalStorage(localStorageName, null);

  const [collapsed, setCollapsed] = React.useState(true);


  if (
    location.pathname == '/login' ||
    !auth ||
    !auth.privileges.permissions
  ) {
    return <Login />;
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Layout
          style={{
            minHeight: '100vh',
          }}
        >
          <Sider
            collapsed={collapsed}
            style={{
              zIndex: 1,
              background: '#fff',
              height: '100vh',
              position: 'fixed',
              overflow: 'auto',
              boxShadow: '0.4px 0.4px 20px #bfbdb644',
            }}
          >
            <SidebarContent />
          </Sider>
          <Layout>
            <Header
              style={{
                background: 'white',
                position: 'fixed',
                zIndex: 100,
                width: collapsed ? 'calc(100% - 79px)' : 'calc(100% - 200px)',
                marginLeft: collapsed ? '79px' : '200px',
                transition: 'all 0.2s',
                paddingInline: '10px',
              }}
            >
              <HeaderContent toggleSidebar={() => setCollapsed(!collapsed)} />
            </Header>
            <Content
              style={{
                background: '#F8FBFF',
                marginLeft: collapsed ? '79px' : '200px',
                padding: '20px 10px',
                marginTop: '64px',
              }}
            >
              <Routes>
                {routes.map((route) => (
                  <Route
                    path={`${route.path}`}
                    key={route.key}
                    element={
                      <Suspense fallback={<>...</>}>
                        <route.component />
                      </Suspense>
                    }
                  />
                ))}
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
