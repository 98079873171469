import { DollarCircleOutlined } from '@ant-design/icons';
import { UilUpload } from '@iconscout/react-unicons';
import { Col, Menu, Row } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from '../../configs/localStorage';
import './SidebarContent.css';

function SidebarContent() {
  const [auth] = useLocalStorage('auth', null);
  const navigate = useNavigate();

  const items = [
    {
      key: '19',
      icon: <UilUpload />,
      label: 'Upload',
      url: '/upload',
    },
    {
      key: '20',
      icon: <DollarCircleOutlined />,
      label: 'Exchange',
      url: '/exchange',
    },
  ];

  return (
    <Row>
      <Col span={24} align="middle">
        <img
          src="https://play-lh.googleusercontent.com/VRYmahhs3v6rARILk40Rf2dmUKOWJXwNjNi7cUme0iytSYd6YWNb5XtmGa6oZqExhQ"
          style={{
            width: '70%',
            height: 'auto',
            marginBlock: '1rem',
            transition: 'all 0.2s',
            borderRadius: '10px',
          }}
          alt="logo"
        />
      </Col>
      <Col span={24}>
        <Menu
          mode="inline"
          items={items}
          defaultSelectedKeys={
            [
              // items.find((item) => item.url === location.pathname).key,
            ]
          }
          style={{
            background: 'transparent',
            color: 'grey',
            fontWeight: 'bold',
          }}
          onClick={(e) => {
            const selectedItem = items.find((item) => item.key === e.key);
            navigate(selectedItem.url);
          }}
        />
      </Col>
    </Row>
  );
}

export default SidebarContent;
