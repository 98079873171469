import { lazy } from 'react';

const Login = lazy(() => import('./pages/Login'));
const PredefinedMessages = lazy(() => import('./pages/PredefinedMessages'));
const upload = lazy(() => import('./pages/upload'));
const exchangeRate = lazy(()=> import('./pages/ExchangeRate'));

const options = [
  {
    key: Math.random(),
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/predefined-messages',
    component: PredefinedMessages,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/upload',
    component: upload,
    exact: true,
  },
  {
    key: Math.random(),
    path: '/exchange',
    component: exchangeRate,
    exact: true,
  },
];
export default options;
